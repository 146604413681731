$.validator.methods.phone = function (value, element) {
  return this.optional(element) || /[0-9\-\(\)\s]+./.test(value);
};

$(() => {
  const $redeemForm = $('.js-gift-redeem-form');
  if (!$redeemForm.length) { return; }

  const initValidator = () => {
    $redeemForm.validate({
      onkeyup: false,
      rules: {
        'member[email]': {
          required: true,
          email: true,
          remote: {
            data: {
              email() { return $('#member_email').val(); },
              token: $('#token').val(),
              member_id: $('#member_id').val()
            },
            type: 'get',
            url: $('[data-validate-redeem-email-url]').data('validate-redeem-email-url'),
            dataFilter: (data) => {
              const { valid, error } = JSON.parse(data);

              if (valid) { return true; }

              $('#member_email').data('message', error);
              return false;
            }
          }
        },
        'member[contact_number]': {
          phone: true
        },
        'member[password]': {
          required: true,
          minlength: 8
        },
        'member[first_name]': {
          required: true
        },
        'member[last_name]': {
          required: true
        },
        'member[address_attributes][address_control]': {
          required: true
        },
        // eslint-disable-next-line quote-props
        'membership_term_and_conditions': {
          required: true
        },
      },
      messages: {
        membership_term_and_conditions: 'You need to accept the terms and conditions.',
        'member[contact_number]': 'Enter a valid phone number',
        'member[email]': {
          remote() { return $('#member_email').data('message'); }
        }
      },
      focusInvalid: true,
      errorClass: 'invalid',
      errorElement: 'div',
      ignore: 'input[type="text"]:hidden',
      showErrors(errorMap, errorList) {
        errorList.forEach((el) => {
          if (el.element.type !== 'checkbox') {
            // eslint-disable-next-line no-param-reassign
            el.message = `<div class='arrow'></div>${el.message}`;
          }
        });
        return this.defaultShowErrors();
      },
      errorPlacement(error, element) {
        // hide server-side error if present
        element.next('.field-error').remove();
        // workarround to add styled arrow
        error.addClass('field-error');

        if (element[0].type === 'checkbox') {
          error.addClass('nt3 tc');
          error.insertAfter(element.parent().parent());
        } else {
          error.insertAfter(element);
        }
      }
    });
  };

  // Validation set up
  initValidator();
});
