/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.validator.methods.phone = function (value, element) {
  return this.optional(element) || /[0-9\-\(\)\s]+./.test(value);
};

$(() => {
  const $body = $('body');
  if ($('.membership-form').length <= 0) { return; }

  return $('html').on('join.form.ready', (e) => $('.membership-form').validate({
    onkeyup: false,
    rules: {
      'member[member_attributes][contact_number]': {
        phone: true
      },
      'member[member_attributes][password]': {
        required: true,
        minlength: 8
      },
      'member[member_attributes][first_name]': {
        required: true
      },
      'member[member_attributes][last_name]': {
        required: true
      },
      membership_term_and_conditions: {
        required: true
      },
      'member[member_attributes][address_attributes][address_control]': {
        required: true
      },
      membership_term_and_conditions: {
        required: true
      },
      'member[promo_code_id]': {
        required() {
          return ($('.js-promo-input').val().length > 0) || ($('.js-promo-code-id').val().length > 0);
        },
        remote: {
          data: {
            membership() {
              return JSON.stringify({
                membership_product_id: $("input[name='member[membership_product_id]']").val(),
                include_magazine: $("input[name='member[include_magazine]']").val(),
                promo_discount: $("input[name='member[promo_discount]']").val(),
                promo_code_id: $("input[name='member[promo_code_id]']").val()
              });
            },
            promo_code() {
              return $("input[name='promo_code']").val();
            }
          },
          type: 'GET',
          url: $('[data-validate-promo-url]').data('validate-promo-url'),
          dataType: 'JSON'
        }
      }
    },
    messages: {
      membership_term_and_conditions: 'You need to accept the terms and conditions.',
      'member[member_attributes][contact_number]': 'Enter a valid phone number',
      'member[promo_code_id]': 'Please apply a valid code',
      'member[member_attributes][email]': {
        remote: $('#member_member_attributes_email').data('message')
      }
    },
    focusInvalid: true,
    errorClass: 'invalid',
    errorElement: 'div',
    ignore: 'input[type="text"]:hidden',
    showErrors(errorMap, errorList) {
      errorList.forEach((el) => {
        if (el.element.type !== 'checkbox') {
          return el.message = `<div class='arrow'></div>${el.message}`;
        }
      });
      return this.defaultShowErrors();
    },
    errorPlacement(error, element) {
      // hide server-side error if present
      element.next('.field-error').remove();
      // workarround to add styled arrow
      error.addClass('field-error');

      if (element[0].type === 'checkbox') {
        return error.insertBefore(element.parent().parent());
      } if (element[0].name === 'member[promo_code_id]') {
        window.promoCodes.setInvalidPromoCode();
        error.addClass('js-promo-id-error w-100 mt3 tl');
        return error.insertAfter($('.js-promo-message'));
      }
      return error.insertAfter(element);
    }

  }));
});
